/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import NimbleHero from '../../components/hero';
import HomePromotions from './components/promotions/homePromotions.component';
import ProofPoints from '../../components/proofPoints';
import TestimonialsComponent from '../../components/testimonials';
import FAQComponent from '../../components/faq';
import CardsComponent from '../../components/cards';

const Homepage = ({
  pageData,
  componentData
}) => {
  const heroData = componentData.filter(
    data => data.__typename === 'ContentfulNimbleHero'
  );
  const promotionsData = componentData.filter(
    data => data.componentId === 'Homepage Promotions or Notifications'
  );
  const proofPointsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentProofPoints'
  );
  const faqsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentFaq'
  );
  const testimonialsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentTestimonials'
  );
  const loanData = componentData.filter(
    data => data.__typename === 'ContentfulComponentCards'
  );

  const heroTextBottom = pageData
    ? JSON.parse(pageData.internal.content).heroTextBottom
    : null;

  return (
    <>
      <NimbleHero data={heroData[0]} heroTextBottom={heroTextBottom} />
      {!!promotionsData.length && promotionsData[0].text && (
        <HomePromotions promotionsData={promotionsData[0]} />
      )}
      {!!proofPointsData.length && (
        <ProofPoints data={proofPointsData[0]} />
      )}
      {!!loanData.length && (
        <CardsComponent data={loanData[0]} />
      )}
      {!!testimonialsData.length && (
        <TestimonialsComponent data={testimonialsData[0]} />
      )}
      {!!faqsData.length && (
        <FAQComponent data={faqsData[0]} />
      )}
    </>
  );
};

export default Homepage;
