import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../../../elements/richText';

const Promotions = styled.div`
  background-color: ${props => props.theme.colours.green};
  padding: 1px 0;
`;

const PromotionsContainer = styled.div`
  position: relative;
  max-width: 1260px;
  margin: 20px auto;
  padding-left: 60px;
  padding-right: 60px;
  color: #ffffff;
  text-align: center;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 30px;
    padding-right: 30px;
  }

  a {
    color: #ffffff;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: #ffffff;
      text-decoration: none;
    }
  }
`;

const HomePromotions = ({ promotionsData }) => {
  const {
    text
  } = promotionsData;

  return (
    <Promotions>
      <PromotionsContainer>
        <RichText text={text} />
      </PromotionsContainer>
    </Promotions>
  );
};
HomePromotions.propTypes = {
  promotionsData: PropTypes.shape({
    text: PropTypes.shape({
      raw: PropTypes.string,
      references: PropTypes.arrayOf(PropTypes.object)
    })
  })
};
export default HomePromotions;
